import React from 'react';
import './App.css';
import PhotoGallery from './PhotoGallery';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <PhotoGallery />
      </header>
    </div>
  );
}

export default App;
