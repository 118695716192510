import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import './App.css'; // Ensure this points to the correct path of your CSS file

const PhotoGallery = () => {
    const [organizedPhotos, setOrganizedPhotos] = useState({});
    const [enlargedPhoto, setEnlargedPhoto] = useState(null); // Track which photo is currently enlarged

    useEffect(() => {
        const fetchPhotos = async () => {
            const s3 = new AWS.S3({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.REACT_APP_REGION,
            });

            let isTruncated = true;
            let ContinuationToken;
            const allPhotos = [];

            while (isTruncated) {
                const params = {
                    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    Prefix: '2023/', // Adjust based on your needs
                    ContinuationToken,
                };

                try {
                    const data = await s3.listObjectsV2(params).promise();
                    allPhotos.push(...data.Contents);
                    isTruncated = data.IsTruncated;
                    ContinuationToken = data.NextContinuationToken;
                } catch (err) {
                    console.log(err, err.stack);
                    break;
                }
            }

            organizePhotos(allPhotos);
        };

        fetchPhotos();
    }, []);

    const organizePhotos = (photos) => {
        const organized = photos.reduce((acc, photo) => {
            const parts = photo.Key.split('/').filter(part => part); // Remove any empty strings
            if (parts.length >= 3) { // Adjust based on your structure
                const [year, location] = parts;
                const photoId = parts.join('/'); // Unique identifier for each photo
                if (!acc[year]) acc[year] = {};
                if (!acc[year][location]) acc[year][location] = [];
                acc[year][location].push({ ...photo, photoId });
            }
            return acc;
        }, {});

        setOrganizedPhotos(organized);
    };

    const toggleEnlargement = (photoId) => {
        if (enlargedPhoto === photoId) {
            setEnlargedPhoto(null); // Minimize if the same photo is clicked again
        } else {
            setEnlargedPhoto(photoId); // Enlarge the clicked photo
        }
    };

    return (
        <div>
            {Object.keys(organizedPhotos).sort().map(year => (
                <div key={year}>
                    <h2>{year}</h2>
                    {Object.keys(organizedPhotos[year]).sort().map(location => (
                        <div key={location}>
                            <div className="photo-gallery">
                                {organizedPhotos[year][location].map((photo, index) => (
                                    <img
                                        key={photo.photoId}
                                        src={`https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${photo.Key}`}
                                        alt={`${location} ${index}`}
                                        className={enlargedPhoto === photo.photoId ? 'enlarged' : ''}
                                        onClick={() => toggleEnlargement(photo.photoId)}
                                        onDragStart={(e) => e.preventDefault()} // Prevent image dragging
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default PhotoGallery;
